<template>
  <div v-bind="$attrs">
    <v-overlay v-if="isLoading" v-model="isLoading" color="white" absolute>
      <div class="d-flex flex-column align-center justify-center text-center" style="gap: 1rem">
        <v-progress-linear color="primary" style="max-width: 15rem" indeterminate/>
        <span class="text--disabled">Loading countries...</span>
      </div>
    </v-overlay>

    <div v-else class="h-100">
      <GoogleMaps
        v-model="markers"
        :loading="loading"
        :disabled="loading"
        map-id="glosarx"
        show-label
        show-title
        class="w-100 h-100"
        @click:marker="onClickMarker"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {Vue, Component, VModel, Prop} from 'vue-property-decorator';
import TrackerModel from '@/models/tracker.model';
import CountryModel from '@/modules/sdk/models/country.model';
import CountryService from '@/modules/sdk/services/country.service';
import GoogleMaps, {IMarker} from '@/modules/common/components/GoogleMaps.vue';

@Component({
  components: {
    GoogleMaps,
  }
})
export default class TrackerViewMap extends Vue {
  @VModel({ type: TrackerModel, default: () => new TrackerModel() }) tracker!: TrackerModel;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Array, default: () => ([]) }) rows!: Array<{[key: string]: string}>;

  loadingCountries = false
  countryList: Array<CountryModel> = []
  csvCountryMapping: { [key: string]: string } = {}

  get isLoading(): boolean {
    return this.loading || this.loadingCountries;
  }

  get markers(): Array<IMarker> {
    const markers: Array<IMarker> = [];

    if (this.countryList.length === 0) {
      return markers;
    }

    this.rows.forEach(row => {
      const rowCountries = this.splitValues((row['Country(ies)'] || '').toString());
      rowCountries.forEach(rowCountry => {
        rowCountry = rowCountry.trim().toLowerCase();
        if (this.csvCountryMapping[rowCountry]) {
          rowCountry = this.csvCountryMapping[rowCountry];
        }
        rowCountry = rowCountry.toLowerCase().trim();

        const found = this.countryList.find(country => {
          const countryLabel = country.data.label.toLowerCase().trim();
          return countryLabel === rowCountry;
        });
        if (found) {
          const foundInMarkers = markers.find(marker => marker.meta.category === found.data.label);
          if (!foundInMarkers) {
            markers.push({
              color: found.data.color,
              shape: found.data.shape,
              content: found.data.label,
              label: 1,
              meta: {
                category: found.data.label,
                rows: [row],
              },
              position: {
                lat: found.data.latitude,
                lng: found.data.longitude,
              }
            })
          } else if (foundInMarkers.meta) {
            foundInMarkers.meta.rows.push(row);
            if (typeof foundInMarkers.label === 'number') {
              foundInMarkers.label++;
            }
          }
        } else {
          console.error('COUNTRY NOT FOUND', rowCountry);
        }
      });
    });
    return markers;
  }

  onClickMarker(event: {
    marker: IMarker,
    item: any
  }) {
    const rows = event.marker.meta ? event.marker.meta.rows : [];
    this.$emit('click:item', {
      rows,
      titlePrefix: event.marker.meta.category,
      selectDataSourceFirst: rows.length > 1,
    });
  }

  splitValues(value: string): Array<string> {
    if (value.includes('"')) {
      try {
        return JSON.parse('[' + value + ']');
      } catch {
        return [value];
      }
    }
    return value.split(',');
  }

  loadCountries(): Promise<Array<CountryModel>> {
    this.loadingCountries = true;
    return CountryService.getInstance().getAll().then((response: any) => {
      this.countryList = response.data.view.list;
      return this.countryList;
    }).finally(() => this.loadingCountries = false);
  }

  created() {
    this.loadCountries();
  }
}
</script>
