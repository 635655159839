import {ILegend} from '@/interfaces';
import {heatmapLegendsList} from '@/enums/global';
import tinycolor from 'tinycolor2';
import Query from '@/modules/sdk/core/query';
import { Column } from '@/components/HeatmapBuilder.vue';
import TrackerModel from '@/models/tracker.model';
import { opts } from '@/plugins/vuetify';

const getHeaders = (tracker: TrackerModel, prefix = '___'): Array<{[key: string]: string}> => {
  return [
    { value: 'Name of Data Source', text: 'Name of Data Source', align: 'left', class: 'v-align-top pt-3' },
    ...tracker.data.heatmap.data.columns.map((item: any) => ({
      value: prefix + item.name,
      text: item.name,
      meta: item,
      class: 'pt-3 pb-1 v-align-top',
      align: 'center',
    }))
  ]
}

const getLegends = (tracker: TrackerModel, rows: Array<{[key: string]: string}>): Array<ILegend> => {
  const filteredLegends: Array<ILegend> = [];
  const filteredHeatmapRows = getFilteredHeatmapRows(tracker, rows);
  filteredHeatmapRows.forEach(row => {
    if (tracker) {
      tracker.data.heatmap.data.columns.forEach((column: any) => {
        const attrs = getHeatmapColumnAttrs(tracker, column, row);
        const color = (attrs.style.backgroundColor || '').toUpperCase();
        const legend = filteredLegends.find(legend => legend.color === color);
        if (!legend && color) {
          const theme: any = opts.theme?.themes?.light;
          const heatmapLegend = heatmapLegendsList.find(heatmapLegend => {
            return (theme[heatmapLegend.color] || '').toString().toUpperCase() === color;
          });
          const trackerLegend = tracker.data.heatmap.data.legends.find((item: any) => item.color === color);
          const text = trackerLegend
            ? trackerLegend.text
            : (heatmapLegend && heatmapLegend.text);
          const dark = tinycolor(color).isDark();
          if (text) {
            filteredLegends.push({
              color,
              text,
              dark,
            })
          }
        }
      })
    }
  })

  const sortedLegends: Array<ILegend> = [];
  tracker.data.heatmap.data.legends.forEach((legend: any) => {
    const filteredLegend = filteredLegends.find(filteredLegend => filteredLegend.color === legend.color);
    if (filteredLegend) {
      sortedLegends.push(filteredLegend);
    }
  });

  return sortedLegends;
}

const getFilteredHeatmapRows = (
  tracker: TrackerModel,
  rows: Array<{[key: string]: string}>,
  prefix = '___',
): Array<{[key: string]: string}> => {
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  const headers = getHeaders(tracker);
  const items: Array<{[key: string]: string}> = [];
  const legendIndexes: {[key: string]: string} = tracker.data.heatmap.data.legends.reduce((obj: any, legend: any, index: number) => {
    obj[legend.color.substring(0, 7)] = letters.substring(index, index + 1);
    return obj;
  }, {});
  rows.forEach(row => {
    const item: {[key: string]: string} = {};
    Object.keys(row).forEach(key => {
      item[key] = row[key];
    })
    headers.slice(1).forEach((header: any) => {
      item[prefix + header.text] = legendIndexes[header.meta.color.substring(0, 7)] + '_' + header.meta.color;
      for (let i = 0; i < header.meta.items.length; i++) {
        const _item = header.meta.items[i];
        if (Query.analyze(_item.queries, row, _item.match)) {
          item[prefix + header.text] = legendIndexes[_item.color.substring(0, 7)] + '_' + _item.color;
          break;
        }
      }
    })
    items.push(item)
  });
  return items;
}

const getHeatmapColumnAttrs = (
  tracker: TrackerModel,
  column: Column,
  row: {[key: string]: string}
): {[key: string]: any} => {
  const attrs = {
    style: {
      backgroundColor: column.color,
      outline: 'rgba(255, 255, 255, 0.6) solid 1px',
      cursor: 'pointer',
      width: 'calc(' + (50 / tracker.data.heatmap.data.columns.length) + '%)',
    }
  };
  for (let i = 0; i < column.items.length; i++) {
    const item = column.items[i];
    if (Query.analyze(item.queries, row, item.match)) {
      attrs.style.backgroundColor = item.color;
      return attrs;
    }
  }
  return attrs;
}

export {
  getHeaders,
  getLegends,
  getFilteredHeatmapRows,
  getHeatmapColumnAttrs,
}
