<template>
  <v-card class="h-100 d-flex flex-column" :color="selectedPoints.length > 0 ? 'blue lighten-5' : null">
    <v-card-title
      class="pr-1 pt-0 align-center"
    >
      <div style="flex: 1" class="py-2 d-flex align-center overflow-hidden justify-space-between">
        <span>
          <v-icon v-if="!readonly" class="handle mr-3">mdi-drag</v-icon>
          <span v-text="title || widget.title" class="text-truncate"></span>
        </span>
        <div class="mr-3 d-flex align-center" style="gap: 1rem">
          <v-checkbox
            v-if="widget.options.chart.type === 'pie' && widget.allowCombineMultipleValues"
            v-model="combineMultipleValues"
            :label="'Combine ' + (widget.combineMultipleValuesLabel?.toLowerCase() || 'multiple values')"
            class="ma-0 pa-0"
            hide-details
          ></v-checkbox>
          <v-tooltip v-if="widget.queryOverride" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                small
                color="warning"
              >
                <v-icon small left>mdi-database-lock</v-icon>
                <span>Overriden</span>
              </v-chip>
            </template>
            <span>The results of this chart are independent from the advanced search builder and other chart filters</span>
          </v-tooltip>
          <v-chip v-if="selectedPoints.length > 0" small color="primary" close @click:close="$emit('clear-filters')">
            <v-icon small left>mdi-filter-variant</v-icon>
            <span>Filtering</span>
          </v-chip>
        </div>
      </div>
      <div v-if="!readonly" style="flex: 0" class="px-2 d-flex align-center">

        <!-- LAYOUT -->
        <MenuTooltip
          :menu-attrs="{ bottom: true, left: true,  closeOnContentClick: false }"
          :btn-attrs="{ icon: true, small: true }"
          :icon-attrs="{ small: true }"
          icon="mdi-view-dashboard"
          tooltip="Layout"
        >
          <v-card style="width: 15rem">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <h4>Layout arrangement</h4>
                </v-col>
                <v-col cols="6">
                  <v-card class="pa-3" color="background" tile flat>
                    <v-row v-for="rowIdx in 3" :key="'row_' + rowIdx" dense>
                      <v-col v-for="colIdx in 2" :key="'col_' + colIdx" :cols="tileSize" style="padding: 0.1rem">
                        <v-card
                          :height="12 * rowIdx + 12"
                          :style="{
                            borderRadius:
                              (rowIdx === 1 && colIdx === 1 ? '0.5rem' : '0')
                              + ' ' + (rowIdx === 1 && colIdx === 2 ? '0.5rem' : '0')
                              + ' ' + (rowIdx === 3 && colIdx === 2 ? '0.5rem' : '0')
                              + ' ' + (rowIdx === 3 && colIdx === 1 ? '0.5rem' : '0')
                          }"
                          :color="widget.x === tileSize * (colIdx - 1) && widget.w === tileSize && widget.h === (tileHeight * rowIdx + tileHeight) ? 'primary' : 'sheet'"
                          @click="setWidgetSizePosition(widget, tileSize * (colIdx - 1), tileSize, tileHeight * rowIdx + tileHeight)"
                        ></v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="pa-3" color="background" tile flat>
                    <v-row v-for="rowIdx in 3" :key="'row_' + rowIdx" dense>
                      <v-col cols="12" style="padding: 0.1rem">
                        <v-card
                          :height="12 * rowIdx + 12"
                          :style="{
                            borderRadius: '0.5rem'
                          }"
                          :color="widget.x === 0 && widget.w === 12 && widget.h === (tileHeight * rowIdx + tileHeight) ? 'primary' : 'sheet'"
                          @click="setWidgetSizePosition(widget, 0, 12, tileHeight * rowIdx + tileHeight)"
                        ></v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </MenuTooltip>

        <!-- SETTINGS -->
        <MenuTooltip
          :menu-attrs="{ bottom: true, left: true }"
          :btn-attrs="{ icon: true, small: true }"
          :icon-attrs="{ small: true }"
          icon="mdi-dots-vertical"
          tooltip="Settings"
        >
          <v-list dense>
            <v-list-item @click="onEditSettings(widget)">
              <v-list-item-icon>
                <v-icon>mdi-card-bulleted-settings-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="$t('btn.settings')"></v-list-item-title>
            </v-list-item>
            <v-list-item @click="onRemoveWidget(widget)">
              <v-list-item-icon>
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="$t('btn.remove')"></v-list-item-title>
            </v-list-item>
          </v-list>
        </MenuTooltip>
      </div>
    </v-card-title>

    <v-card-text style="position: relative; min-height: 5rem" :class="{
      'h-100': true,
      'overflow-auto': widget.settings.scrollable,
      'overflow-hidden': !widget.settings.scrollable,
    }">
      <DashboardChartSettingsDialog
        v-if="settingsDialog.model && !readonly"
        v-model="settingsDialog.model"
        :visible.sync="settingsDialog.visible"
        :fields="fields"
        :data="finalData"
        :definitions="definitions"
        :is-model="isModel"
        :load-override="loadOverride"
        :query-builder="queryBuilder"
        scrollable
        @on-apply="onApplyWidget"
      />
      <v-overlay
        v-if="loading"
        opacity="0.05"
        dark
        absolute
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </v-overlay>
      <div v-else-if="widget.options.chart.type === 'datatable' && widget.table.headers.length === 0" class="w-100 pa-12 text-center h-100 d-flex align-center justify-center text-center" style="border: rgba(0, 0, 0, 0.2) dashed 3px; border-radius: 0.5rem">
        No header defined
      </div>
      <div v-else-if="widget.options.chart.type !== 'datatable' && (widget.series.length === 0 || widget.series.length === 1 && widget.series[0].field === '')" class="w-100 pa-12 text-center h-100 d-flex align-center justify-center text-center" style="border: rgba(0, 0, 0, 0.2) dashed 3px; border-radius: 0.5rem">
        No serie defined
      </div>
      <div v-else-if="finalData.length === 0" class="w-100 pa-12 text-center h-100 d-flex align-center justify-center text-center" style="border: rgba(0, 0, 0, 0.2) dashed 3px; border-radius: 0.5rem">
        No data available
      </div>
      <DashboardChartItem
        v-else-if="loaded"
        ref="chart"
        :value="widget"
        :skip-watch="skipWatch"
        :data="finalData"
        :definitions="definitions"
        :is-model="isModel"
        :readonly="readonly"
        :explorable="explorable"
        :combine-multiple="combineMultipleValues"
        :combine-multiple-label="widget.combineMultipleValuesLabel"
        :can-select-color="canSelectColor"
        @on-point-click="props => $emit('on-point-click', props)"
        @click:row="row => $emit('click:row', row)"
      />
    </v-card-text>
    <v-card-text v-if="widget.options.chart.type === 'pie' && widget.allowCombineMultipleValues && !combineMultipleValues">
      <span class="text--disabled">* Number of data sources are not mutually exclusive</span>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, VModel, Prop, Watch, Ref} from 'vue-property-decorator'
import DashboardChartModel, { IDashboardChart } from '@/modules/sdk/models/dashboard-chart.model';
import DashboardChartSettingsDialog from '@/modules/common/components/DashboardChartSettingsDialog.vue';
import DashboardChartItem from '@/modules/common/components/DashboardChartItem.vue';
import Query, { IQueryItem } from '@/modules/sdk/core/query';
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue';

@Component({
  components: {
    DashboardChartSettingsDialog,
    DashboardChartItem,
    MenuTooltip,
  }
})
export default class DashboardChart extends Vue {
  @Ref() readonly chart!: DashboardChartItem
  @VModel() widget!: IDashboardChart;

  @Prop({ type: Boolean, default: false, }) explorable?: boolean;
  @Prop({ type: Boolean, default: false, }) readonly?: boolean;
  @Prop({ type: Boolean, default: false, }) skipWatch?: boolean;
  @Prop({ type: Array, default: null, }) data!: Array<any> | null;
  @Prop({ type: Array, default: null, }) definitions!: Array<any> | null;
  @Prop({ type: Array, default: null, }) fields!: Array<any> | null;
  @Prop({ type: Array, default: () => ([]), }) filters!: Array<IQueryItem>;
  @Prop({ type: Array, default: () => ([]), }) selectedPoints!: Array<any>;
  @Prop({ type: Boolean, default: false }) combineMultiple!: boolean;
  @Prop({ type: String, default: null, }) title!: string;
  @Prop({ type: Boolean, default: true }) isModel!: boolean;
  @Prop({ type: Boolean, default: false }) canSelectColor!: boolean;
  @Prop({ type: Function, default: () => () => {} }) queryBuilder!: any;
  @Prop({ type: Function, default: () => new Promise(resolve => resolve) }) loadOverride!: (widget: IDashboardChart) => Promise<any>

  tileSplit = 2
  tileHeight = 4
  loading = false
  loaded = false
  combineMultipleValues = false
  finalData: Array<any> = []
  internalData: Array<any> | null = null
  settingsDialog: {
    visible: boolean,
    model: IDashboardChart,
  } = {
    visible: false,
    model: new DashboardChartModel().data as any,
  }

  @Watch('combineMultiple')
  onCombineMultipleChange(value: boolean) {
    this.combineMultipleValues = value;
  }

  @Watch('widget.queryOverride', { deep: true })
  onWidgetQueryOverrideChanged(queryOverride: boolean) {
    if (queryOverride && this.internalData === null) {
      this.load().then(() => {
        this.updateFinalData();
      });
    } else {
      this.updateFinalData();
    }
  }

  @Watch('filters', { deep: true })
  onWidgetFiltersChanged() {
    this.updateFinalData();
  }

  onEditSettings(widget: IDashboardChart) {
    Object.assign(this.settingsDialog, {
      visible: true,
      model: structuredClone(widget),
    })
  }

  onRemoveWidget(widget: IDashboardChart) {
    this.$root.$shouldTakeAction.askDelete().then(response => {
      if (response) {
        this.$emit('on-remove', widget);
      }
    })
  }

  onApplyWidget(widget: IDashboardChart) {
    Object.assign(this.widget, widget);
    this.chart.update();
  }

  get tileSize(): number {
    return 12 / this.tileSplit;
  }

  get tileAmount(): number {
    return 12 / this.tileSize;
  }

  updateChartSize(): void {
    if (this.chart) {
      this.chart.updateSize();
    }
  }

  refresh(): void {
    if (this.widget.queryOverride) {
      this.load().then(() => {
        this.updateFinalData();
      });
    }
  }

  setWidgetSizePosition(widget: IDashboardChart, xPos: number, width: number, height?: number) {
    widget.x = xPos;
    widget.w = width;
    widget.h = height || widget.h;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    })
  }

  setWidgetHeight(widget: IDashboardChart, height: number) {
    widget.h = height;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  updateFinalData() {
    const data = this.widget.queryOverride ? (this.internalData || []) : this.data || [];
    this.finalData = Query.filterItems(this.filters, data);
  }

  load(): Promise<any> {
    this.loading = true;
    return this.loadOverride(this.widget).then(data => {
      this.internalData = data;
      this.loading = false;
    })
  }

  created() {
    if (!this.widget.queryOverride) {
      // Needs to be in a setTimeout to make sure Highcharts has the time to initialize
      setTimeout(() => {
        this.updateFinalData();
        this.loaded = true;
      })
    } else {
      this.load().then(() => {
        this.updateFinalData();
        this.loaded = true;
      });
    }

    this.combineMultipleValues = this.combineMultiple;
  }
}
</script>
