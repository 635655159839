<template>
  <div class="d-flex flex-column">
    <div v-if="loading || parsing" class="d-flex flex-column align-center justify-center text-center" style="flex:1; gap: 1rem">
      <v-progress-linear color="primary" style="max-width: 15rem" indeterminate/>
      <span class="text--disabled">Loading dashboards...</span>
    </div>

    <!-- DASHBOARD -->
    <template v-else>
      <v-alert
        v-if="syncedItems.length === 0"
        class="mb-0 h-100"
        text
        type="info"
      >
        No dashboard yet
      </v-alert>
      <dashboard-builder
        v-for="(item, itemIdx) in syncedItems"
        v-model="syncedItems[itemIdx]"
        v-bind="dashboardAttrs"
        :key="item.autoIncrementId"
        :items="syncedItems"
        :filters="computedFilterList"
        :fields="computedFields"
        :data="computedData"
        :definitions="computedDefinitions"
        :hide-actions="hideActions"
        :user-id="userId"
        :readonly="userId !== syncedItems[itemIdx].data.userId && userId !== null"
        :class="{ 'mt-4': itemIdx > 0 }"
        :disable-chart-actions="disableChartActions"
        :is-model="false"
        :explorable="explorable"
        :load-override="onLoadOverride"
        :before-save="model => onBeforeSave(model, tracker)"
        :query-builder="model => ({
          is: TrackerQueryBuilder,
          filterList: queryBuilderFilterList,
          disabled: model.queryOverride,
          isModel: false,
        })"
        style="flex: 1"
        ref="dashboard_builder"
        @add="onAddDashboard"
        @on-show-data="props => $emit('on-show-data', props)"
      >
        <template v-if="!userId || userId === syncedItems[itemIdx].data.userId" #bottom_left>
          <v-btn
            :disabled="!canDelete(item)"
            :loading="item.states.deleting"
            color="error"
            outlined
            @click="onDeleteClick(item, itemIdx)"
          >
            {{ item.data.id ? 'Delete' : 'Remove' }}
          </v-btn>
        </template>
      </dashboard-builder>
    </template>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop, PropSync } from 'vue-property-decorator';
import HeatmapBuilder from '@/components/HeatmapBuilder.vue';
import TrackerQueryBuilder from '@/components/TrackerQueryBuilder.vue';
import DataForm from '@/modules/common/components/DataForm.vue';
import TrackerModel from '@/models/tracker.model';
import VisualizationService from '@/services/visualization.service';
import DashboardBuilder from '@/modules/common/components/DashboardBuilder.vue';
import CollapsableCard from '@/modules/common/components/CollapsableCard.vue';
import VisualizationModel from '@/modules/sdk/models/visualization.model';
import Tracker from '@/tracker';
import TrackerService from '@/services/tracker.service';
import Query from '@/modules/sdk/core/query';
import { IDashboardChart } from '@/modules/sdk/models/dashboard-chart.model';
import {IDefinition} from '@/interfaces';

@Component({
  components: {
    HeatmapBuilder,
    DataForm,
    DashboardBuilder,
    CollapsableCard,
    TrackerQueryBuilder,
  }
})
export default class TrackerDashboardBuilderForm extends Vue {

  @VModel({ default: () => new VisualizationModel() }) model!: VisualizationModel
  @PropSync('items', { default: () => ([]) }) syncedItems!: Array<VisualizationModel>
  @PropSync('filters', { default: () => ([]) }) computedFilterList!: Array<any>
  @Prop({ default: () => new TrackerModel() }) tracker!: TrackerModel
  @Prop({ type: Array, default: () => ([]) }) definitions!: IDefinition[]
  @Prop({ default: () => ({}) }) dashboardAttrs!: any
  @Prop({ default: () => null }) data!: Array<any>
  @Prop({ type: Array, default: () => ([]) }) headers!: Array<any>
  @Prop({ type: Number, default: null }) userId!: number
  @Prop({ type: Boolean, default: false }) explorable!: boolean
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: Boolean, default: false }) hideActions!: boolean
  @Prop({ type: Boolean, default: false }) hideCollapsable!: boolean
  @Prop({ type: Boolean, default: false }) disableChartActions!: boolean

  parsing = false
  innerData: any = null
  innerDefinitions: Array<any> | null = null
  innerFields: Array<any> | null = null
  queryBuilderFilterList: Array<any> = []
  TrackerQueryBuilder = TrackerQueryBuilder

  get computedDefinitions(): Array<any> {
    return this.innerDefinitions ? this.innerDefinitions : this.definitions;
  }

  get computedData(): Array<any> {
    return this.innerData ? this.innerData : this.data;
  }

  get computedFields(): Array<any> {
    return this.innerFields ? this.innerFields : this.headers.map((item: any) => item.text);
  }

  canDelete(model: VisualizationModel): boolean {
    return !model.states.deleting && (this.userId === null || this.userId === model.data.userId);
  }

  onBeforeSave(model: VisualizationModel, tracker: TrackerModel) {
    return new Promise(resolve => {
      model.data.trackerId = tracker.data.id;
      resolve(model);
    });
  }

  onLoadOverride(widget: IDashboardChart) {
    return TrackerService.getInstance().get({ id: this.tracker.data.id })
      .then((response: any) => {
        if (response.data.view.single) {
          return new Tracker().loadFile(response.data.view.single)
            .then(results => {
              return Query.filterItems(widget.filters, results.rows);
            })
        }
        return response;
      })
  }

  onAddDashboard(model: VisualizationModel) {
    this.$emit('add', model);
  }

  onDeleteClick(model: VisualizationModel, index: number) {
    const id = model.data.id;
    if (!id) {
      this.syncedItems.splice(index, 1);
      this.$emit('remove');
      return;
    }

    this.$root.$shouldTakeAction.askDelete().then((response: boolean) => {
      if (response) {
        model.states.deleting = true;
        VisualizationService.getInstance().delete({ id })
          .then(() => {
            this.syncedItems.splice(index, 1);
            this.$root.$globalSnack.success({
              message: this.$i18n.t('dataForm.snack.itemDeleted')
            });
            this.$emit('delete', id);
          })
          .catch(reason => this.$root.$zemit.handleError(reason))
          .finally(() => model.states.deleting = false);
      }
    })
  }

  parseTracker() {
    this.parsing = true;
    new Tracker().loadFile(this.tracker)
      .then(results => {
        this.innerData = results.rows;
        this.innerDefinitions = results.definitions;
        this.innerFields = results.filters.map((item: any) => item.label);
        this.queryBuilderFilterList = results.filters;
      })
      .finally(() => this.parsing = false);
  }

  created() {
    if (this.data === null) {
      this.parseTracker();
    } else {
      this.queryBuilderFilterList = this.computedFilterList;
    }
  }
}
</script>
