<template>
  <div ref="map"></div>
</template>

<script lang="ts">
/* eslint-disable no-undef */
import { Vue, Component, VModel, Prop, Ref, Watch } from 'vue-property-decorator';
import Hash from '@/modules/sdk/core/hash';

export interface ILatLng {
  lat: number,
  lng: number,
}

export const markerShapes = [
  { text: 'Pin', value: 'pin', icon: 'mdi-map-marker' },
  { text: 'Circle', value: 'circle', icon: 'mdi-circle' },
  { text: 'Square', value: 'square', icon: 'mdi-square' },
]

export interface IMarker {
  content?: string,
  label?: string | number,
  shape?: 'pin' | 'circle' | 'square',
  color?: string,
  icon?: `mdi-${string}`,
  meta?: any,
  position: ILatLng,
}

let initMapInterval: number;
let skipWatchMarkers = false;

@Component
export default class GoogleMaps extends Vue {
  @Ref() map!: HTMLDivElement

  @VModel() markers!: IMarker[];
  @Prop({ type: String, default: Hash.guid() }) mapId!: string;
  @Prop({ type: Boolean, default: false }) showLabel!: boolean;
  @Prop({ type: Boolean, default: false }) showTitle!: boolean;
  @Prop({ type: Boolean, default: false }) streetViewControl!: boolean;
  @Prop({ type: Boolean, default: false }) disableDefaultUi!: boolean;
  @Prop({ type: Boolean, default: true }) scrollwheel!: boolean;
  @Prop({ type: Boolean, default: true }) mapTypeControl!: boolean;
  @Prop({ type: String, default: 'terrain' }) mapTypeId!: 'terrain' | 'satellite' | 'hybrid' | 'roadmap';
  @Prop({ type: Boolean, default: false }) draggable?: boolean;
  @Prop({ type: Number, default: 2 }) zoom!: number;
  @Prop({ type: Object, default: () => ({ lat: 30, lng: 0 }) }) center!: ILatLng;

  @Watch('markers', { deep: true })
  onMarkersChanged() {
    if (!skipWatchMarkers) {
      this.applyMarkers();
    }
  }

  instance: google.maps.Map | null = null;
  appliedItems: google.maps.marker.AdvancedMarkerElement[] = []

  get options() {
    return {
      // Default
      mapId: this.mapId,
      mapTypeId: this.mapTypeId,
      center: this.center,
      zoom: this.zoom,

      // Advanced
      mapTypeControl: this.mapTypeControl,
      streetViewControl: this.streetViewControl,
      disableDefaultUI: this.disableDefaultUi,
      scrollwheel: this.scrollwheel,
    }
  }

  clearMarkers() {
    for (let i = 0; i < this.appliedItems.length; i++) {
      const appliedItem = this.appliedItems[i];
      // @ts-ignore
      if (!this.markers.find(marker => appliedItem.marker === marker)) {
        appliedItem.map = null;
      }
    }
  }

  applyMarkers() {
    this.clearMarkers();
    this.markers.forEach(marker => {
      const zIndex = 100000 - (marker.position.lat ? parseInt((marker.position.lat + 50).toString()) * 100 : 1);
      const options: google.maps.marker.AdvancedMarkerElementOptions = {
        map: this.instance,
        position: marker.position,
        zIndex,
      }
      if (marker.shape) {
        const container = document.createElement('div');
        container.classList.add((marker.shape || 'pin') + '-tag', 'marker-tag');

        const shape = document.createElement('div');
        shape.classList.add('shape');
        shape.style.setProperty('--color', marker.color || this.$vuetify.theme?.themes?.light?.error?.toString() || '');

        if (marker.icon) {
          const icon = document.createElement('i');
          icon.classList.add('v-icon', 'notranslate', 'mdi', marker.icon);
          shape.appendChild(icon);
          shape.classList.add('has-icon');
        }

        if (marker.label) {
          const label = document.createElement('i');
          label.classList.add('label');
          label.innerHTML = marker.label.toString();
          shape.appendChild(label);
          shape.classList.add('has-label');
        }

        if (marker.content) {
          const content = document.createElement('div');
          content.classList.add('marker-content')
          content.innerHTML = marker.content;
          container.appendChild(content);
        }

        container.appendChild(shape);
        options.content = container;
      }

      // eslint-disable-next-line no-new
      const item = new google.maps.marker.AdvancedMarkerElement(options);
      item.gmpDraggable = this.draggable;
      // @ts-ignore
      item.marker = marker;

      if (this.$listeners['click:marker'] !== undefined) {
        item.addListener('click', () => {})
      }
      let mouseLeaveTimeout: number;
      const markerEvents = ['click', 'dragstart', 'drag', 'dragend'];
      markerEvents.forEach(eventName => {
        item.content?.addEventListener(eventName, () => {
          if (item.position) {
            skipWatchMarkers = true;
            // @ts-ignore
            marker.position.lat = item.position.lat;
            // @ts-ignore
            marker.position.lng = item.position.lng;
            this.$nextTick(() => skipWatchMarkers = false);
          }
          this.$emit(eventName + ':marker', {
            item,
            marker,
          });
        })
      })
      item.content?.addEventListener('mouseenter', () => {
        clearTimeout(mouseLeaveTimeout);
        item.zIndex = 100000;
        this.$emit('mouseenter:marker', {
          item,
          marker,
        });
      })
      item.content?.addEventListener('mouseleave', () => {
        clearTimeout(mouseLeaveTimeout);
        mouseLeaveTimeout = setTimeout(() => {
          item.zIndex = zIndex;
        }, 150);
        this.$emit('mouseleave:marker', {
          item,
          marker,
        });
      })
      this.appliedItems.push(item);
    })
  }

  mounted() {
    initMapInterval = setInterval(() => {
      if (google) {
        clearInterval(initMapInterval);
        this.instance = new google.maps.Map(this.map, this.options)
        this.applyMarkers();
      }
    }, 250);
  }
}
</script>

<style lang="scss">
.marker-tag {
  position: relative;

  .shape {
    transform: translateY(1rem);
    border: rgba(0, 0, 0, 0.33) solid 1px;

    &.no-transform {
      transform: translateY(0) !important;
    }

    // Dot
    &:not(.has-icon):not(.has-label)::after {
      left: 50%;
      top: 50%;
      border-radius: 50%;
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.25);
      content: "";
      z-index: 2;
    }

    // Label
    &.has-label .label {
      left: 50%;
      top: 50%;
      position: absolute;
      font-style: normal;
      font-weight: bold;
      transform: translate(-50%, -50%);
      z-index: 2;
      color: white;
    }

    // Icon
    .mdi {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.pin-tag {
    .shape {
      background-color: var(--color);
      border-radius: 50%;
      transition: all 0.3s ease-out;
      width: 2rem;
      height: 2rem;
      font-size: 0.8rem;
      z-index: 1;
      transform: translateY(-1rem);
    }

    &::before {
      height: 0;
      width: 0;
      left: 50.5%;
      top: 33%;
      border-left: 0.80rem solid transparent;
      border-right: 0.80rem solid transparent;
      border-top-style: solid;
      border-top-width: 1.25rem;
      border-top-color: rgba(0, 0, 0, 0.333);
      content: "";
      position: absolute;
      transform: translate(-50%, 0);
      transition: all 0.3s ease-out;
    }

    & .shape::before,
    & .shape .before {
      height: 0;
      width: 0;
      left: 50.5%;
      top: 80%;
      border-left: 0.80rem solid transparent;
      border-right: 0.80rem solid transparent;
      border-top-style: solid;
      border-top-width: 1.30rem;
      border-top-color: var(--color);
      content: "";
      position: absolute;
      transform: translate(-50%, 0);
      transition: all 0.3s ease-out;
      z-index: 2;
    }

    .marker-content {
      top: -0.75rem;
    }
  }

  &.circle-tag {
    .shape {
      background-color: var(--color);
      border-radius: 50%;
      position: relative;
      transition: all 0.3s ease-out;
      width: 2rem;
      height: 2rem;
      font-size: 0.8rem;
    }
  }

  &.square-tag {
    .shape {
      background-color: var(--color);
      border-radius: 5%;
      position: relative;
      transition: all 0.3s ease-out;
      width: 2rem;
      height: 2rem;
      font-size: 0.8rem;

      &:not(.has-icon)::after {
        border-radius: 0;
      }
    }
  }

  .marker-content {
    position: absolute;
    background-color: white;
    display: flex;
    align-items: center;
    height: 1.5rem;
    text-wrap: nowrap;
    overflow: hidden;
    border-radius: 1rem;
    padding-left: 0;
    padding-right: 0;
    width: 0;
    z-index: 0;
    max-width: max-content;
    left: 0.5rem;
    top: 1.25rem;
    transition: all 150ms ease-in-out;
    pointer-events: none;
  }
  &:hover .marker-content {
    width: 200px;
    padding-left: 2rem;
    padding-right: 0.5rem;
  }
}
</style>
