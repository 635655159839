<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="syncedSaving"
    title="Query Inspector"
  >
    <template #body>
      <v-card class="mt-4">
        <v-card-text>
          <HeatmapBuilder
            v-model="syncedTracker"
            :disabled="syncedDisabled"
          />
        </v-card-text>
      </v-card>
    </template>
    <template #buttons>
      <v-checkbox
        v-model="syncedDisabled"
        hide-details
        label="Disabled"
        class="ma-0 pa-0"
      />
      <v-spacer></v-spacer>
      <v-btn
        :disabled="syncedDisabled || syncedSaving"
        :loading="syncedSaving"
        color="primary"
        @click="onSaveQueryInspector"
      >
        Save
      </v-btn>
      <v-btn
        :disabed="syncedSaving"
        outlined
        @click="visible = false"
      >
        Close
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop, PropSync } from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import HeatmapBuilder from '@/components/HeatmapBuilder.vue';
import TrackerService from '@/services/tracker.service';
import TrackerModel from '@/models/tracker.model';

@Component({
  components: {
    HeatmapBuilder,
    ModalDialog,
  }
})
export default class TrackerViewQueryInspectorDialog extends Vue {
  @VModel({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ type: Number, default: null }) columnIndex!: number;
  @PropSync('tracker', { type: TrackerModel, default: () => new TrackerModel() }) syncedTracker!: TrackerModel;
  @PropSync('disabled', { type: Boolean, default: false }) syncedDisabled!: boolean;
  @PropSync('saving', { type: Boolean, default: false }) syncedSaving!: boolean;

  onSaveQueryInspector() {
    this.syncedSaving = true;

    const heatmap = this.syncedTracker.data.heatmap;
    const clonedColumns = structuredClone(this.syncedTracker.data.heatmap.data.columns);
    const modifiedColumn = heatmap.data.columns[0];
    heatmap.data.columns = clonedColumns;
    heatmap.data.columns[this.columnIndex] = modifiedColumn;

    TrackerService.getInstance().save(this.syncedTracker)
      .then(response => {
        this.syncedTracker.assign(response.data.view.single);
        this.visible = false;
        this.$emit('save', response.data.view.single);
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.syncedSaving = false);
  }
}
</script>
