<template>
  <div v-bind="$attrs" class="d-flex flex-column">

    <!-- EXPORT PDF OVERLAY -->
    <v-overlay :value="generating" z-index="1000">
      <div class="text-center">
        <h2>Generating {{ generating }}</h2>
        <v-progress-linear style="width: 15rem" class="my-3" indeterminate />
        <p>Please wait...</p>
      </div>
    </v-overlay>

    <!-- TOOLBAR -->
    <div class="pa-3 d-flex align-center justify-space-between pb-0" style="flex: 0; gap: 1rem">
      <v-text-field
        v-model="queryList"
        :disabled="loading"
        label="Filter list"
        placeholder="Type your search terms..."
        prepend-inner-icon="mdi-magnify"
        outlined
        hide-details
        clearable
        dense
      />
      <v-btn-toggle
        v-model="sorting"
        dense
        color="primary"
      >
        <v-btn value="asc">
          <v-icon>mdi-sort-ascending</v-icon>
        </v-btn>
        <v-btn value="desc">
          <v-icon>mdi-sort-descending</v-icon>
        </v-btn>
      </v-btn-toggle>
      <ExportRowsToMenu
        v-model="filteredRows"
        :title="filteredRows.length === 1 ? filteredRows[0]['Name of Data Source'] : tracker.data.label"
        :headers="headers"
        :definitions="definitions"
        :disabled="!canExport"
        outlined
        @generating="onGenerating"
      />
    </div>

    <!-- LOADING -->
    <v-overlay v-if="loading" v-model="loading" color="white" absolute>
      <div class="d-flex flex-column align-center justify-center text-center" style="gap: 1rem">
        <v-progress-linear color="primary" style="max-width: 15rem" indeterminate/>
        <span class="text--disabled">Loading data...</span>
      </div>
    </v-overlay>

    <!-- NO DATA FOUND -->
    <div v-else-if="filteredRows.length === 0" class="h-100 w-100 pa-4">
      <div class="w-100 pa-12 text-center h-100 d-flex align-center justify-center text-center" style="border: rgba(0, 0, 0, 0.2) dashed 3px; border-radius: 0.5rem">
        No data found
      </div>
    </div>

    <!-- CONTENT -->
    <v-list v-else style="overflow: auto; height: 0; flex-grow: 1">
      <v-list-item v-for="(row, rowIdx) in filteredRows" :class="rowIdx % 2 === 1 ? 'backgroundVeryLight' : null" :key="rowIdx" @click="() => onItemClick(row)">
        <v-list-item-icon>
          <v-icon>mdi-book-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="row['Name of Data Source']"></v-list-item-title>
          <v-list-item-subtitle v-text="row['Type of Data Source']"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <InfoSourceActivator
            v-if="row['Info Source']"
            :value="row['Info Source']"
          />
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import ExportRowsToMenu from '@/components/ExportRowsToMenu.vue';
import InfoSourceActivator from '@/components/InfoSourceActivator.vue';
import TrackerModel from '@/models/tracker.model';
import {IDefinition} from '@/interfaces';

@Component({
  components: {
    InfoSourceActivator,
    ExportRowsToMenu,
  }
})
export default class TrackerViewList extends Vue {
  @VModel({ type: TrackerModel, default: () => new TrackerModel() }) tracker!: TrackerModel;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Array, default: () => ([]) }) rows!: Array<{[key: string]: string}>;
  @Prop({ type: Array, default: () => ([]) }) headers!: Array<any>
  @Prop({ type: Array, default: () => ([]) }) definitions!: IDefinition[]

  generating = false;
  sorting: 'asc' | 'desc' | null = null;
  queryList: string | null = null;

  get filteredRows(): Array<{[key: string]: string}> {
    const query = (this.queryList || '').toLowerCase();
    const rows = this.rows.filter(row => (row['Name of Data Source'] || '').toString().toLowerCase().indexOf(query) !== -1);
    return this.sorting ? rows.sort((a: any, b: any) => {
      return this.sorting === 'asc'
        ? (a['Name of Data Source'] > b['Name of Data Source']) ? 1 : -1
        : (a['Name of Data Source'] > b['Name of Data Source']) ? -1 : 1;
    }) : rows;
  }

  get canExport(): boolean {
    return this.filteredRows.length > 0;
  }

  onItemClick(row: {[key: string]: string}) {
    this.$emit('click:item', {
      rows: [row],
    });
  }

  onGenerating(value: any) {
    this.generating = value;
  }
}
</script>
