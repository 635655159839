<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import { json2csv } from 'json-2-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import ExcelJS from 'exceljs'
import {IDefinition} from '@/interfaces';

export interface IExportToUnclassified {
  label: string,
  value: string,
  values: string[],
}

export interface IExportToCategory {
  label: string,
  unclassified: IExportToUnclassified[],
  children: Array<{ [key: string]: string }>,
}

export interface IExportToItem {
  label: string,
  categories: Array<IExportToCategory>
}

export interface IExportToData {
  title: string,
  items: Array<IExportToItem>,
}

@Component({})
export default class ExportRowsToMenu extends Vue {
  @VModel({ type: Array, default: () => ([]) }) rows!: {[key: string]: string}[]
  @Prop({ type: String, default: 'Untitled' }) title!: string
  @Prop({ type: Array, default: () => ([]) }) definitions!: IDefinition[]
  @Prop({ type: Array, default: () => ([]) }) headers!: any[]

  getData(): IExportToData {
    return this.getExportToData(this.rows, this.title);
  }

  downloadAsPDF() {
    this.$emit('generating', 'PDF');
    setTimeout(() => {
      const data = this.getData();
      const doc = new jsPDF();
      const initialY = 20;
      let startY = initialY;

      function drawLine() {
        doc.setLineWidth(0.2);
        doc.setDrawColor(224, 224, 224);
        doc.line(14, startY, 192, startY);
        startY += 10;
      }

      function printText(str: string, size = 12, style = 'normal', color = '#000000') {
        doc.setFontSize(size);
        doc.setFont('Courier', style);
        doc.setTextColor(color);

        const splitTitle = doc.splitTextToSize(str, 192);
        doc.text(splitTitle, 14, startY);
        startY += ((size * splitTitle.length) / 2.5);
      }

      // Main title
      printText(this.title, 24, 'bold');
      startY += 10;

      data.items.forEach((item, itemIdx) => {

        // Print category label if more than one item
        if (data.items.length > 1) {
          if (itemIdx > 0) {
            doc.addPage();
            startY = initialY;
          }
          printText(item.label || 'Untitled', 18, 'bold', '#0073cf');
          drawLine();
        }

        item.categories.forEach(category => {
          const rows: Array<{ label: string, value: string }> = [];
          category.unclassified.forEach(row => {
            if (row.label !== 'Name of Data Source') {
              rows.push({
                label: row.label,
                value: (row.values.length <= 1
                  ? row.values[0]
                  : ('• ' + row.values.join('\n• '))) || '-',
              });
            }
          })

          // Print category label
          if (startY >= 280) {
            doc.addPage();
            startY = initialY;
          }
          printText(category.label || 'Uncategorized', 15, 'bold');

          // Print table
          autoTable(doc, {
            showHead: 'never',
            tableWidth: 'wrap',
            startY,
            columnStyles: {
              label: { cellWidth: 100, fontStyle: 'bold' },
              value: { cellWidth: 82 },
            },
            columns: [
              { header: 'Label', dataKey: 'label' },
              { header: 'Value', dataKey: 'value' },
            ],
            body: rows,
          })

          // @ts-ignore
          startY = doc.lastAutoTable.finalY + 15;
        })
      })

      const nameOfDataSource = this.title || 'Untitled';
      doc.save(nameOfDataSource + '.pdf');

      this.$emit('generating', false);
    })
  }

  downloadAsExcel() {
    this.$emit('generating', 'Excel');
    setTimeout(() => {
      const data = this.getData();
      const workbook = new ExcelJS.Workbook();
      workbook.creator = 'GlosaRx';
      workbook.created = new Date();

      data.items.forEach(item => {
        const sheet = workbook.addWorksheet((item.label || '').replace(/[^a-zA-Z0-9]/g, ''));
        sheet.columns = [
          { width: 50 },
          { width: 50 },
        ];

        item.categories.forEach(category => {
          const rowCategory = sheet.addRow([(category.label || 'Uncategorized').replace(/[^a-zA-Z0-9]/g, '')]);
          rowCategory.getCell(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' },
            bgColor: { argb: '000000' }
          }

          category.unclassified.forEach(row => {
            if (row.label !== 'Name of Data Source') {
              sheet.addRow([
                row.label,
                (row.values.length <= 1
                  ? row.values[0]
                  : row.values.join('\n')) || '-'
              ]);
            }
          })
        })
      });

      workbook.xlsx.writeBuffer().then(buffer => {
        // @ts-ignore
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.title + '.xlsx';
        link.click();
        link.remove();
        this.$emit('generating', false);
      });
    })
  }

  downloadAsCSV() {
    this.$emit('generating', 'CSV');
    setTimeout(() => {
      let csv = '';
      this.getData().items.forEach(item => {
        csv += item.label + '\n\n';
        item.categories.forEach(category => {
          csv += (category.label || 'Uncategorized') + '\n';
          csv += json2csv(category.unclassified.map(item => ({
            label: item.label,
            value: item.value,
          })), {
            arrayIndexesAsKeys: true,
            expandNestedObjects: true,
            prependHeader: false,
          }) + '\n\n'
        })
      });
      const data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', this.title + '.csv');
      link.click();
      link.remove();
    })
    this.$emit('generating', false);
  }

  downloadAsJSON() {
    this.$emit('generating', 'JSON');
    setTimeout(() => {
      const json = JSON.stringify(this.getData());
      const data = 'data:text/json;charset=utf-8,' + encodeURIComponent(json);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', this.title + '.json');
      link.click();
      link.remove();
    })
    this.$emit('generating', false);
  }

  getExportToData(items: Array<any>, title: string): IExportToData {
    const newItems: Array<IExportToItem> = [];
    items.forEach(item => {
      newItems.push({
        label: item['Name of Data Source'],
        categories: this.getGroupedData(item),
      });
    })

    return {
      title,
      items: newItems,
    }
  }

  getGroupedData(row: any) {
    const currentKeys = Object.keys(row);
    const results: Array<any> = [];
    this.headers.forEach((header, headerIdx: number) => {
      let newCategory = results.find(item => item.label === header.category);
      if (!newCategory) {
        newCategory = {
          label: header.category,
          keys: [],
          children: [],
          unclassified: [],
        };

        const subCategories = [...new Set(this.definitions.filter(definition => definition.subCategory && definition.category === header.category))];
        subCategories.forEach(subCategory => {
          let newSubCategory = newCategory.children.find((item: any) => subCategory.subCategory && item.label === subCategory.subCategory);
          if (!newSubCategory) {
            newSubCategory = {
              key: subCategory.name,
              label: subCategory.subCategory,
              children: [],
            }
            newCategory.children.push(newSubCategory);
          }
          const keys = currentKeys.filter(key => key === subCategory.name);
          keys.forEach(key => {
            newSubCategory.children.push({
              label: key,
              value: row[key],
            })
          })
        });
        results.push(newCategory);
      }
      newCategory.keys.push(currentKeys[headerIdx]);
    });

    results.forEach(category => {
      const remainingKeys: Array<string> = category.keys.filter((key: string) => {
        return !category.children.find((subCategory: any) =>
            subCategory.key === key || subCategory.children.find((subSubCategory: any) => {
              return subSubCategory.label === key;
            })
        )
      });
      if (remainingKeys.length > 0) {
        remainingKeys.forEach(key => {
          category.unclassified.push({
            label: key,
            value: row[key],
          })
        })
      }
    });

    results.forEach(category => {
      category.unclassified.forEach((item: IExportToUnclassified) => {
        const definition: any = this.definitions.find(definition => definition.name === item.label) || { single: true };
        const value = (item.value || '').toString();
        item.values = definition.single
          ? [value]
          : value.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g) || [];
        item.values = item.values.map(value => {
          return value.trim().substring(
            value.trim().startsWith('"') ? 1 : 0,
            value.trim().endsWith('"') ? value.length - 1 : value.length,
          );
        });
      });
    });

    return results;
  }
}
</script>

<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="{ ...$attrs, ...attrs }" v-on="on">
        Export as...
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="downloadAsPDF">
        <v-list-item-icon>
          <v-icon>mdi-file-document-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>PDF</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="downloadAsExcel">
        <v-list-item-icon>
          <v-icon>mdi-file-excel-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Excel</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="downloadAsCSV">
        <v-list-item-icon>
          <v-icon>mdi-file-table-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>CSV</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="downloadAsJSON">
        <v-list-item-icon>
          <v-icon>mdi-code-braces</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>JSON</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
