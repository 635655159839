<template>
  <DataForm
    ref="dataForm"
    v-test-id="'heatmap'"
    v-model="model"
    v-bind="$attrs"
    :service="service"
    name="heatmap"
    title="Heatmap"
    title-icon="mdi-thermometer-lines"
    hide-back-button
    hide-delete-button
  >
    <template #options>
      <PresetManager
        v-if="model.data.id"
        :value="model.data"
        :default-item="defaultPresetItem"
        :loading="loadingPresets"
        :saving="savingPreset"
        :default-item-args="{
          trackerId: model.data.id,
        }"
        :load-callback="loadPresets"
        :save-callback="savePresets"
        :remove-callback="removePreset"
        ref="presetManager"
        id="heatmap_filters"
        label="Presets"
        hide-details="auto"
        outlined
        dense
        clearable
        style="width: 25rem"
        @input="onApplyPresetFilters"
      />
    </template>
    <template #form="{ formErrors, rules }">
      <HeatmapBuilder
        v-model="model"
        :form-errors="formErrors"
        :definitions="definitions"
        :rules="rules"
        @legend-sort="onLegendSort"
      />
    </template>
  </DataForm>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import HeatmapBuilder from '@/components/HeatmapBuilder.vue';
import { defaultQueryBuilderItem } from '@/modules/common/components/QueryBuilder.vue';
import DataForm from '@/modules/common/components/DataForm.vue';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import TrackerModel from '@/models/tracker.model';
import TrackerService from '@/services/tracker.service';
import HeatmapFilterStateService from '@/services/heatmap-filter-state.service';
import Identity from '@/modules/sdk/core/identity';
import HeatmapFilterStateModel from '@/models/heatmap-filter-state.model';
import TrackerQueryBuilder from '@/components/TrackerQueryBuilder.vue';
import { IDefinition } from '@/interfaces';

@Component({
  components: {
    TrackerQueryBuilder,
    HeatmapBuilder,
    DataForm,
    PresetManager,
  }
})
export default class HeatmapBuilderForm extends Vue {

  @VModel({ default: () => new TrackerModel() }) model!: TrackerModel
  @Prop({ type: Array, default: () => ([]) }) definitions!: Array<IDefinition>

  service = TrackerService.getInstance();
  loadingPresets = false
  savingPreset = false
  defaultPresetItem: Array<any> = [defaultQueryBuilderItem];

  onLegendSort(legends: any[]) {
    this.model.data.heatmap.data.legends = legends;
  }

  loadPresets(): Promise<any> {
    return HeatmapFilterStateService.getInstance().getAll({
      filters: [{
        field: 'trackerId',
        operator: 'equals',
        value: this.model.data.id,
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  savePresets(item: HeatmapFilterStateModel) {
    return HeatmapFilterStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  removePreset(item: HeatmapFilterStateModel) {
    return HeatmapFilterStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  onApplyPresetFilters(preset: any) {
    this.model.assign(preset);
  }
}
</script>
