<template>
  <div v-bind="$attrs">

    <!-- HEADERS -->
    <v-sheet v-if="loaded" class="px-4 d-flex align-center justify-space-between" color="backgroundVeryLight" style="z-index: 1; gap: 1rem; flex: 0">
      <v-chip-group
        v-model="selectedDashboardIdx"
        active-class="primary--text text--accent-4 primary"
        mandatory
      >
        <v-chip
          v-for="(dashboard, dashboardIdx) in dashboards.filter(dashboard => dashboard.data.id)"
          :value="dashboardIdx"
          :key="dashboard.autoIncrementId"
          :disabled="isLoading"
          outlined
        >
          {{ dashboard.originalData.title }}
          <v-icon v-if="currentUserId !== dashboard.data.userId" small right>mdi-lock</v-icon>
        </v-chip>
        <v-chip
          :value="dashboards.filter(dashboard => dashboard.data.id).length"
          :key="-1"
          :disabled="isLoading"
          outlined
          @click="onAddDashboardClick"
        >
          <v-icon color="primary">mdi-plus</v-icon>
        </v-chip>
      </v-chip-group>

      <div>
        <v-btn
          :disabled="!canDuplicate"
          outlined
          color="primary"
          block
          small
          @click="onDuplicateDashboard"
        >
          <v-icon small left>mdi-content-copy</v-icon>
          Duplicate
        </v-btn>
      </div>
    </v-sheet>

    <!-- DASHBOARD BUILDER -->
    <TrackerDashboardBuilderForm
      v-model="dashboard"
      :items="dashboards[selectedDashboardIdx] && [dashboards[selectedDashboardIdx]] || []"
      :tracker="tracker"
      :filters="filters"
      :headers="headers"
      :data="rows"
      :definitions="definitions"
      :user-id="currentUserId"
      :loading="isLoading"
      :dashboard-attrs="{ flat: true, tile: true }"
      explorable
      id="tracker_view_dashboard_builder"
      style="flex: 1"
      hide-collapsable
      @add="onAddDashboard"
      @delete="onDeleteDashboard"
      @remove="onRemoveDashboard"
      @on-show-data="onShowData"
    />
  </div>
</template>

<script lang="ts">
import {Vue, Component, VModel, Prop, Watch} from 'vue-property-decorator';
import TrackerModel from '@/models/tracker.model';
import TrackerDashboardBuilderForm from '@/views/Admin/Form/TrackerDashboardBuilderForm.vue';
import VisualizationModel from '@/modules/sdk/models/visualization.model';
import VisualizationService from '@/services/visualization.service';
import Identity from '@/modules/sdk/core/identity';
import {IFilter} from '@/modules/sdk/core/query';
import {IDefinition} from '@/interfaces';

let skipOnSelectedDashboardIdxChange = false;

@Component({
  components: {
    TrackerDashboardBuilderForm,
  }
})
export default class TrackerViewVisualization extends Vue {
  @VModel({ type: TrackerModel, default: () => new TrackerModel() }) tracker!: TrackerModel;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Array, default: () => ([]) }) rows!: Array<{[key: string]: string}>;
  @Prop({ type: Array, default: () => ([]) }) headers!: Array<any>
  @Prop({ type: Array, default: () => ([]) }) filters!: Array<IFilter>
  @Prop({ type: Array, default: () => ([]) }) definitions!: IDefinition[]

  @Watch('selectedDashboardIdx')
  onSelectedDashboardIdxChange(newVal: number, oldVal: number) {
    if (skipOnSelectedDashboardIdxChange) {
      return;
    }
    if (this.dashboards[oldVal] && oldVal !== newVal) {
      if (this.dashboards[oldVal].isDifferentFromOriginal()) {
        this.$root.$shouldTakeAction.ask(
          this.$i18n.t('dataForm.beforeLeaveRoute.title'),
          this.$i18n.t('dataForm.beforeLeaveRoute.body'),
          'Continue',
          'warning',
          true
        )
          .then(response => {
            if (response) {
              if (this.dashboards[oldVal].data.id) {
                this.dashboards[oldVal].revertData();
              } else {
                this.dashboards.splice(oldVal, 1);
              }
              this.selectedDashboardIdx = newVal;
            }
          })
        this.$nextTick(() => {
          skipOnSelectedDashboardIdxChange = true;
          this.selectedDashboardIdx = oldVal;
          setTimeout(() => {
            skipOnSelectedDashboardIdxChange = false;
          })
        })
      }
    }
  }

  currentUserId = Identity.identity?.user.id
  selectedDashboardIdx = 0
  loadingDashboards = false
  loaded = false
  dashboards: Array<VisualizationModel> = []
  dashboard: VisualizationModel = new VisualizationModel({
    title: 'Tracker Visualization',
    graphs: [],
  })

  get isLoading(): boolean {
    return this.loading || this.loadingDashboards;
  }

  get canDuplicate(): boolean {
    return !this.isLoading && this.dashboards[this.selectedDashboardIdx]?.data.id;
  }

  onShowData(props: {
    rows: Array<any>,
    point: Array<any>,
  }) {
    this.$emit('click:item', {
      rows: props.rows,
      selectDataSourceFirst: props.rows.length > 1,
      titlePrefix: props.point[0]?.name || '',
    });
  }

  onAddDashboardClick(): void {
    this.dashboards = this.dashboards.filter(dashboard => dashboard.data.id);
    this.dashboards.push(new VisualizationModel({
      trackerId: this.tracker.data.id,
      userId: this.currentUserId,
    }))
    this.selectedDashboardIdx = this.dashboards.length - 1;
  }

  onAddDashboard(model: VisualizationModel) {
    this.dashboards = this.dashboards.filter(dashboard => dashboard.data.id);
    this.dashboards.push(model);
    this.selectedDashboardIdx = this.dashboards.length - 1;
  }

  onDuplicateDashboard() {
    const dashboard = this.dashboards[this.selectedDashboardIdx];
    if (dashboard) {
      const model = dashboard.clone(false) as VisualizationModel;
      model.data.id = null;
      model.data.userId = this.currentUserId;
      model.setOriginalData();
      this.onAddDashboard(model);
    }
  }

  onDeleteDashboard(id: number) {
    this.dashboards = this.dashboards.filter(dashboard => dashboard.data.id && dashboard.data.id !== id);
    this.selectedDashboardIdx = 0;
  }

  onRemoveDashboard() {
    this.dashboards = this.dashboards.filter(dashboard => dashboard.data.id);
    this.selectedDashboardIdx = this.dashboards.length - 1;
  }

  loadDashboards(tracker: TrackerModel): Promise<Array<VisualizationModel>> {
    this.loadingDashboards = true;
    return VisualizationService.getInstance().getAll({
      filters: [{
        field: 'trackerId',
        operator: 'equals',
        value: tracker.data.id,
      }, {
        field: 'deleted',
        operator: 'equals',
        value: '0'
      }]
    })
      .then(response => {
        this.dashboards = response.data.view.list;
        if (this.dashboards.length > 0) {
          this.dashboard = response.data.view.list[0];
        } else {
          this.dashboard = new VisualizationModel();
        }
        this.loaded = true;
        this.dashboard.setOriginalData();
        return response.data.view.list;
      })
      .finally(() => this.loadingDashboards = false);
  }
}
</script>
