<template>
  <v-container fluid class="pa-4">

    <!-- IRREGULAR FILE DIALOG -->
    <ModalDialog
      v-model="irregularFileModal.visible"
      icon="mdi-alert"
      title="New File Format"
      max-width="800"
      background-color="background"
      scrollable
    >
      <template #header>
        <v-alert type="warning" :icon="false" class="mb-0 px-6" tile>
          We found fields in the heatmap that are not part of the new file you are trying to upload. Please adjust your CSV or map the missing fields to the matching ones of the new file before applying your changes.
        </v-alert>
      </template>
      <template #body>
        <v-card class="mt-4">
          <v-card-text>
            <ArrayMapper
              v-model="irregularFileModal.mapping"
              :first="irregularFileModal.lostColumns"
              :second="irregularFileModal.newTracker.headers"
              allow-duplicates
              start-empty
            />
          </v-card-text>
        </v-card>
      </template>
      <template #buttons>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="irregularFileModal.loading"
          :loading="irregularFileModal.loading"
          color="primary"
          @click="onApplyIrregularFileFormatClick"
        >
          Apply
        </v-btn>
        <v-btn
          :disabled="irregularFileModal.loading"
          outlined
          @click="irregularFileModal.visible = false"
        >
          Cancel
        </v-btn>
      </template>
    </ModalDialog>

    <DataForm
      v-test-id="'tracker'"
      v-model="model"
      :service="service"
      :loading="loading"
      :on-before-save="onBeforeSave"
      :on-after-save="onAfterSave"
      name="tracker"
      title="Tracker"
    >
      <template #form="{ formErrors, rules }">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="model.data.label"
                :error-messages="formErrors.label"
                :rules="[rules.required]"
                outlined
                label="Tracker"
                hide-details="auto"
                clearable
                required
                @input="delete formErrors.label"
              />
            </v-col>
            <v-col cols="12">
              <FileModelInput
                v-model="model.data.datafileentity"
                :error-messages="formErrors.dataFileId"
                outlined
                ref="dataFileInput"
                hide-details="auto"
                show-size
                chips
                label="CSV Data File"
                accept="text/csv"
                @change="delete formErrors.dataFileId"
              ></FileModelInput>
            </v-col>
            <v-col cols="12">
              <FileModelInput
                v-model="model.data.definitionfileentity"
                :error-messages="formErrors.definitionFileId"
                outlined
                ref="definitionFileInput"
                hide-details="auto"
                show-size
                chips
                label="CSV Definition File"
                accept="text/csv"
                @change="delete formErrors.definitionFileId"
              ></FileModelInput>
            </v-col>
            <v-col cols="12">
              <FileModelInput
                v-model="model.data.abbreviationfileentity"
                :error-messages="formErrors.abbreviationFileId"
                outlined
                ref="abbreviationFileInput"
                hide-details="auto"
                show-size
                chips
                label="CSV Abbreviation File"
                accept="text/csv"
                @change="delete formErrors.abbreviationFileId"
              ></FileModelInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="model.data.footerNotes"
                :error-messages="formErrors.footerNotes"
                label="Footer Notes"
                outlined
                hide-details="auto"
                clearable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <soft-delete-autocomplete
                v-model="adminUserList"
                :loading="userLoading"
                :items="userNodeByType('admin')"
                :item-text="(item) => item.data.userentity.getLabel()"
                outlined
                label="Admins"
                hide-details="auto"
                deletable-chips
                multiple
                chips
                clearable
                return-object
              ></soft-delete-autocomplete>
            </v-col>
            <v-col cols="12">
              <soft-delete-autocomplete
                v-model="viewerUserList"
                :loading="userLoading"
                :items="userNodeByType('viewer')"
                :item-text="(item) => item.data.userentity.getLabel()"
                outlined
                label="Viewers"
                hide-details="auto"
                deletable-chips
                multiple
                chips
                clearable
                return-object
              />
            </v-col>
          </v-row>
        </template>
    </DataForm>

    <!-- QUERY BUILDER -->
    <CollapsableCard v-model="advancedSearchPresetsCollapsed" class="mt-4" v-if="model.data.id">
      <template #title>
        <div class="text-truncate">
          <v-icon left>mdi-magnify</v-icon>
          <span>Advanced Search Presets</span>
        </div>
        <v-spacer/>
      </template>
      <template #options>
        <PresetManager
          v-if="model.data.id"
          :value="filterList"
          :default-item="defaultPresetItem"
          :loading="loadingPresets"
          :saving="savingPreset"
          :default-item-args="{
            trackerId,
          }"
          :load-callback="loadPresets"
          :save-callback="savePresets"
          :remove-callback="removePreset"
          ref="presetManager"
          id="tracker_filters"
          label="Presets"
          hide-details="auto"
          outlined
          dense
          clearable
          style="width: 25rem"
          @input="onApplyPresetFilters"
        />
      </template>
      <template #body>
        <v-card-text>
          <!-- CONTENT -->
          <TrackerQueryBuilder
            v-model="filterList"
            :filter-list="presetFields"
          />
        </v-card-text>
      </template>
    </CollapsableCard>

    <HeatmapBuilderForm
      v-if="model.data.id"
      v-model="trackerClone"
      :definitions="definitions"
      id="heatmap_builder"
      ref="heatmap_builder_form"
      class="mt-4"
      collapsed
      @submit="onSubmitHeatmap"
    />

    <CollapsableCard v-model="dashboardBuilderCollapsed" class="mt-4">
      <template #title>
        <v-icon left>mdi-chart-bar</v-icon>
        <span>Visualizations</span>
      </template>
      <template #options>
        <PresetManager
          v-if="model.data.id"
          :value="dashboard.data"
          :default-item="defaultVisualizationPresetItem"
          :loading="loadingVisualizationPresets"
          :saving="savingVisualizationPreset"
          :default-item-args="{
            visualizationId: model.data.id,
          }"
          :load-callback="loadVisualizationPresets"
          :save-callback="saveVisualizationPresets"
          :remove-callback="removeVisualizationPreset"
          ref="presetManager"
          id="visualization_filters"
          label="Presets"
          hide-details="auto"
          outlined
          dense
          clearable
          style="width: 25rem"
          @input="onApplyVisualizationPresetFilters"
        />
      </template>
      <template #body>
        <v-card-text class="background">
          <TrackerDashboardBuilderForm
            v-if="model.data.id"
            v-model="dashboard"
            :items.sync="dashboards"
            :tracker="model"
            id="tracker_dashboard_builder"
            disable-chart-actions
            collapsed
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            block
            @click="onAddClick"
          >
            Add new dashboard
          </v-btn>
        </v-card-actions>
      </template>
    </CollapsableCard>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component} from 'vue-property-decorator';
import TrackerService from '@/services/tracker.service';
import TrackerModel from '@/models/tracker.model';
import UserService from '@/modules/sdk/services/user.service';
import TrackerUserModel from '@/models/tracker-user.model';
import UserModel from '@/modules/sdk/models/user.model';
import Logger from '@/modules/sdk/core/logger';
import File from '@/modules/sdk/core/file';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import ArrayMapper from '@/modules/common/components/ArrayMapper.vue';
import CollapsableCard from '@/modules/common/components/CollapsableCard.vue';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import DataForm from '@/modules/common/components/DataForm.vue';
import SoftDeleteAutocomplete from '@/modules/common/components/SoftDeleteAutocomplete.vue';
import FileModelInput from '@/modules/common/components/FileModelInput.vue';
import HeatmapBuilderForm from '@/views/Admin/Form/HeatmapBuilderForm.vue';
import TrackerDashboardBuilderForm from '@/views/Admin/Form/TrackerDashboardBuilderForm.vue';
import HeatmapModel from '@/models/heatmap.model';
import Tracker from '@/tracker';
import FileService from '@/modules/sdk/services/file.service';
import VisualizationModel from '@/modules/sdk/models/visualization.model';
import VisualizationService from '@/services/visualization.service';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import { defaultQueryBuilderItem } from '@/modules/common/components/QueryBuilder.vue';
import TrackerFilterStateService from '@/services/tracker-filter-state.service';
import TrackerFilterStateModel from '@/models/tracker-filter-state.model';
import TrackerQueryBuilder from '@/components/TrackerQueryBuilder.vue';
import Identity from '@/modules/sdk/core/identity';
import VisualizationFilterStateService from '@/services/visualization-filter-state.service';
import VisualizationFilterStateModel from '@/modules/sdk/models/visualization-filter-state.model';
import { IDefinition } from '@/interfaces';

const d = new Logger('views/Admin/Form/TrackerForm');

@Component({
  components: {
    Wysiwyg,
    HeatmapBuilderForm,
    DataForm,
    FileModelInput,
    ModalDialog,
    ArrayMapper,
    TrackerDashboardBuilderForm,
    CollapsableCard,
    PresetManager,
    TrackerQueryBuilder,
    SoftDeleteAutocomplete,
  }
})
export default class TrackerForm extends Vue {

  service = TrackerService.getInstance();
  trackerId = 0;
  loading = false;
  userLoading = false
  parsing = false
  dashboardBuilderCollapsed = true
  advancedSearchPresetsCollapsed = true
  userList = [];
  heatmap: HeatmapModel = new HeatmapModel();
  trackerClone: TrackerModel = new TrackerModel();
  model: TrackerModel = new TrackerModel();
  dashboard: VisualizationModel = new VisualizationModel();
  dashboards: Array<VisualizationModel> = [];
  definitions: Array<IDefinition> = []
  irregularFileModal: {
    visible: boolean,
    loading: boolean,
    heatmapFields: Array<string>,
    newTracker: {
      headers: Array<any>,
      rows: Array<string>
    },
    lostColumns: Array<string>,
    newColumns: Array<string>,
    mapping: Array<string>,
    resolve: (response: boolean) => Promise<any>,
  } = {
    visible: false,
    loading: false,
    heatmapFields: [],
    newTracker: {
      headers: [],
      rows: [],
    },
    lostColumns: [],
    newColumns: [],
    mapping: [],
    resolve: () => new Promise(() => {}),
  }

  // Filters and options
  filterList: Array<any> = []
  loadingPresets = false;
  savingPreset = false;
  loadingVisualizationPresets = false;
  savingVisualizationPreset = false;
  presetFields: Array<any> = []
  defaultPresetItem: Array<any> = [defaultQueryBuilderItem];

  defaultVisualizationPresetItem: Array<any> = [{}];

  get adminUserList(): Array<TrackerUserModel> {
    return this.userListByType('admin');
  }

  set adminUserList(value: Array<TrackerUserModel>) {
    this.updateModelUserNode(value, 'admin');
  }

  get viewerUserList(): Array<TrackerUserModel> {
    return this.userListByType('viewer');
  }

  set viewerUserList(value: Array<TrackerUserModel>) {
    this.updateModelUserNode(value, 'viewer');
  }

  onApplyPresetFilters(preset: Array<any>) {
    this.filterList = preset;
  }

  onApplyVisualizationPresetFilters(preset: any) {
    this.dashboard.assign(preset);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    })
  }

  onAddClick() {
    this.dashboards.push(new VisualizationModel());
  }

  onApplyIrregularFileFormatClick(): void {
    this.irregularFileModal.loading = true;

    // Apply mapping changes to heatmap before save
    const mapping: Array<Array<string>> = [];
    this.irregularFileModal.lostColumns.forEach((column, columnIdx) => {
      mapping.push([
        column,
        this.irregularFileModal.mapping[columnIdx],
      ]);
    })
    this.applyMappingToHeatmap(mapping, this.trackerClone);

    this.irregularFileModal.resolve(true);
  }

  onAfterSave(): void {
    if (this.irregularFileModal.visible) {
      this.irregularFileModal.visible = false;
      this.irregularFileModal.loading = false;
      if (
        this.irregularFileModal.mapping.find(mapping => !mapping[1])
        || this.irregularFileModal.mapping.length !== this.irregularFileModal.lostColumns.length
      ) {
        this.$root.$globalModal.info(
          'Check the heatmap!',
          'We updated the heatmap builder with the newest value. Please double-check and save it manually!',
          'warning'
        );
        this.$vuetify.goTo('#heatmap_builder')
      } else {
        // @ts-ignore
        this.$refs.heatmap_builder_form.$refs.dataForm.onSubmit();
      }
    }
  }

  onBeforeSave(): Promise<any> {
    this.loading = true;
    return new Promise((resolve) => {
      const promises = [];

      // Construct the right promise to get file's content as CSV
      const dataFile = this.model.data.datafileentity.file;
      if (dataFile) {
        if (dataFile.constructor.name === 'File') {
          promises.push(File.getResult(this.model.data.datafileentity.file));
        } else if (dataFile.size) {
          promises.push(FileService.getInstance().downloadAsString(this.model.data.datafileentity).then(result => result.data));
        }
      }

      // Construct the right promise to get file definition's content as CSV
      const definitionFile = this.model.data.definitionfileentity.file;
      if (definitionFile) {
        if (definitionFile.constructor.name === 'File') {
          promises.push(File.getResult(this.model.data.definitionfileentity.file));
        } else if (definitionFile.size) {
          promises.push(FileService.getInstance().downloadAsString(this.model.data.definitionfileentity).then(result => result.data));
        }
      }

      const heatmapFields = this.getUsedHeatmapFields(this.trackerClone);
      return Promise.all(promises).then(([newFileResult, newDefinitionResult]) => {
        return Promise.all([
          new Tracker().parseContent(newFileResult, newDefinitionResult),
        ]).then(([newTracker]) => {
          const lostColumns: Array<string> = [];
          for (let i = 0; i < heatmapFields.length; i++) {
            if (!newTracker.headers.find((header: any) => heatmapFields[i] === header.text)) {
              lostColumns.push(heatmapFields[i]);
            }
          }

          this.presetFields = newTracker.filters;

          const newColumns: Array<string> = [];
          newTracker.headers
            .filter((header: any) => lostColumns.indexOf(header.text) === -1)
            .forEach((header: any) => newColumns.push(header.text));

          if (lostColumns.length > 0) {
            Object.assign(this.irregularFileModal, {
              visible: true,
              heatmapFields,
              newTracker,
              newColumns,
              mapping: [],
              lostColumns,
              resolve,
            })
          } else {
            resolve(true);
          }
          this.loading = false;
        });
      })
    });
  }

  onSubmitHeatmap(tracker: TrackerModel) {
    this.model.assign(tracker);
    this.trackerClone = this.model.clone();
    this.heatmap = this.trackerClone.data.heatmap;
  }

  applyMappingToHeatmap(mapping: Array<Array<string>>, tracker: TrackerModel): void {
    function applyField(columns: Array<any>, oldValue: string, newValue: string) {
      for (let i = 0; i < columns.length; i++) {
        for (let y = 0; y < columns[i].items.length; y++) {
          const item = columns[i].items[y];
          for (let z = 0; z < item.queries.length; z++) {
            const query = item.queries[z];
            if (query.field === oldValue) {
              query.field = newValue;
            }
            if (query.group.length > 0) {
              applyField(query.group, oldValue, newValue);
            }
          }
        }
      }
    }
    mapping.forEach(mapping => {
      const oldValue = mapping[0];
      const newValue = mapping[1];
      applyField(tracker.data.heatmap.data.columns, oldValue, newValue);
    });
  }

  getUsedHeatmapFields(tracker: TrackerModel): Array<string> {
    function scanColumns(columns: Array<any>, fields: Array<string>): Array<string> {
      for (let i = 0; i < columns.length; i++) {
        if (Array.isArray(columns[i].items)) {
          for (let y = 0; y < columns[i].items.length; y++) {
            const item = columns[i].items[y];
            for (let z = 0; z < item.queries.length; z++) {
              const query = item.queries[z];
              if (query.field && fields.indexOf(query.field) === -1) {
                fields.push(query.field);
              }
              if (query.group.length > 0) {
                scanColumns(query.group, fields);
              }
            }
          }
        }
      }
      return fields;
    }
    const fields: Array<string> = [];
    scanColumns(tracker.data.heatmap.data.columns, fields);
    return fields;
  }

  userListByType(type: string): Array<TrackerUserModel> {
    return this.model.data.usernode?.filter((node: TrackerUserModel) => node.data.type === type && !node.data.deleted);
  }

  userNodesTypeList: {[key: string]: Array<TrackerUserModel>} = {};
  userNodeByType(type: string): Array<TrackerUserModel> {
    if (!this.userNodesTypeList[type] || !this.userNodesTypeList[type].length) {
      this.userNodesTypeList[type] = this.userList.map((user: UserModel) => new TrackerUserModel({
        type: type,
        userId: user.data.id,
        deleted: 0,
        userentity: user
      }))
    }
    return this.userNodesTypeList[type];
  }

  updateModelUserNode(userNode: Array<TrackerUserModel>, type: string) {
    if (!Array.isArray(this.model.data.usernode)) {
      this.model.data.usernode = [];
    }

    // merge array of objects by properties
    for (const source of userNode) {
      source.assignToArrayByProperty(this.model.data.usernode, {userId: source.data.userId, type: type});
    }

    // keep intersect only
    this.model.data.usernode.map((model: TrackerUserModel) => {
      if (model.data.type === type && !userNode.some((model2: TrackerUserModel) => model.data.userId === model2.data.userId)) {
        model.data.deleted = 1;
      }
      return model;
    });
  }

  loadPresets(): Promise<any> {
    return TrackerFilterStateService.getInstance().getAll({
      filters: [{
        field: 'trackerId',
        operator: 'equals',
        value: this.trackerId,
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  savePresets(item: TrackerFilterStateModel) {
    return TrackerFilterStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  removePreset(item: TrackerFilterStateModel) {
    return TrackerFilterStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  loadVisualizationPresets(): Promise<any> {
    return VisualizationFilterStateService.getInstance().getAll({
      filters: [{
        field: 'visualizationId',
        operator: 'equals',
        value: this.model.data.id,
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  saveVisualizationPresets(item: VisualizationFilterStateModel) {
    return VisualizationFilterStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  removeVisualizationPreset(item: VisualizationFilterStateModel) {
    return VisualizationFilterStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => {
        this.model = response.data.view.single;
        this.trackerClone = this.model.clone();
        this.heatmap = this.trackerClone.data.heatmap;
        this.parseTracker(this.model);
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  loadDashboards(id: number): Promise<VisualizationModel> {
    this.loading = true;
    return VisualizationService.getInstance().getAll({
      filters: [{
          field: 'trackerId',
          operator: 'equals',
          value: id,
        }, {
          field: 'deleted',
          operator: 'equals',
          value: '0'
        }]
    })
      .then(response => {
        this.dashboards = response.data.view.list;
        if (this.dashboards.length > 0) {
          this.dashboard = response.data.view.list[0];
        } else {
          this.dashboard = new VisualizationModel();
        }
        return response.data.view.list;
      })
      .finally(() => this.loading = false);
  }

  parseTracker(tracker: TrackerModel) {
    this.parsing = true;
    new Tracker().loadFile(tracker)
      .then(results => {
        this.presetFields = results.filters;
        this.definitions = results.definitions;
      })
      .finally(() => this.parsing = false);
  }

  created() {
    if (this.$route.params.id) {
      this.trackerId = parseInt(this.$route.params.id);
      this.load(this.trackerId);
      this.loadDashboards(this.trackerId);
    }

    this.userLoading = true;
    UserService.getInstance().getAll()
      .then(response => this.userList = response.data.view.list)
      .finally(() => this.userLoading = false);
  }
}
</script>
