<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          x-small
        >
          Info Source
        </v-btn>
      </slot>
    </template>
    <v-list>
      <v-list-item
        v-for="link in splittedLinks"
        :key="link.href"
        :href="link.href"
        :target="link.href ? '_blank' : null"
      >
        <v-list-item-icon>
          <v-icon v-if="link.href">mdi-open-in-new</v-icon>
          <v-icon v-else>mdi-information</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ link.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel } from 'vue-property-decorator';

@Component
export default class InfoSourceActivator extends Vue {
  @VModel({ type: String, default: '' }) model!: string;

  get splittedLinks(): any[] {
    const links: any[] = [];
    const items = (this.model || '').split(' ').map(item => item.split('\n')).flat();
    items.forEach(item => {
      links.push({
        text: item.trim(),
        href: item.trim().includes('http') ? item : null,
      })
    })
    return links;
  }
}
</script>
