<template>
  <div>
    <!-- EXPORT PDF OVERLAY -->
    <v-overlay :value="generating" z-index="1000">
      <div class="text-center">
        <h2>Generating {{ generating }}</h2>
        <v-progress-linear style="width: 15rem" class="my-3" indeterminate />
        <p>Please wait...</p>
      </div>
    </v-overlay>

    <div v-if="!loading && rows.length === 0" class="d-flex flex-column h-100 w-100 pa-4" style="flex: 1">
      <div class="w-100 pa-12 text-center h-100 d-flex align-center justify-center text-center" style="flex: 1; border: rgba(0, 0, 0, 0.2) dashed 3px; border-radius: 0.5rem">
        No data available
      </div>
    </div>
    <v-data-table
      v-else
      :items-per-page="-1"
      :items="rows"
      :headers="getHeaders(tracker)"
      :sort-by.sync="heatmapSortBy"
      :sort-desc.sync="heatmapSortDesc"
      :height="height - 55"
      :loading="loading"
      fixed-header
    >
      <template #item="{ item }">
        <tr>
          <td class="py-3">
            <div class="mb-2">{{ item['Name of Data Source'] }}</div>
            <div class="d-flex align-center" style="gap: 0.5rem">
              <v-btn
                outlined
                x-small
                @click="() => onItemClick(item)"
              >
                View Metadata
              </v-btn>
              <InfoSourceActivator
                v-if="item['Info Source']"
                :value="item['Info Source']"
              />
            </div>
          </td>
          <td
            v-for="(column, columnIdx) in tracker.data.heatmap.data.columns"
            v-bind="getHeatmapColumnAttrs(tracker, column, item)"
            v-on="canClickHeatmapCell ? {
              click: () => onClickHeatmapCell(column, columnIdx)
            } : null"
            v-ripple
            :key="column.name"
          ></td>
        </tr>
      </template>
      <template #footer.prepend>
        <div class="d-flex align-center" style="gap: 1rem">
          <ExportHeatmapToMenu
            v-model="rows"
            :title="tracker.data.label"
            :headers="getHeaders(tracker)"
            :definitions="definitions"
            :disabled="!canExport"
            :legends="legends"
            outlined
            small
            @generating="onGenerating"
          />

          <div v-if="legends.length > 0" class="px-3 d-flex align-center" style="gap: 1rem">
            <h5 class="text--text">Legend:</h5>
            <v-chip-group>
              <v-chip
                v-for="legend in legends"
                :key="legend.color"
                :color="legend.color"
                :dark="legend.dark"
                small
                style="pointer-events: none"
              >
                {{ legend.text }}
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import {Vue, Component, VModel, Prop} from 'vue-property-decorator';
import TrackerModel from '@/models/tracker.model';
import InfoSourceActivator from '@/components/InfoSourceActivator.vue';
import {Column} from '@/components/HeatmapBuilder.vue';
import Identity from '@/modules/sdk/core/identity';
import {getHeaders, getHeatmapColumnAttrs} from '@/heatmap';
import {IDefinition, ILegend} from '@/interfaces';
import ExportHeatmapToMenu from '@/components/ExportHeatmapToMenu.vue';

@Component({
  components: {
    ExportHeatmapToMenu,
    InfoSourceActivator,
  }
})
export default class TrackerViewHeatmap extends Vue {
  @VModel({ type: TrackerModel, default: () => new TrackerModel() }) tracker!: TrackerModel;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Array, default: () => ([]) }) rows!: Array<{[key: string]: string}>;
  @Prop({ type: Array, default: () => ([]) }) legends!: Array<ILegend>;
  @Prop({ type: Array, default: () => ([]) }) definitions!: IDefinition[]
  @Prop({ type: Number, default: 400 }) height!: number;

  heatmapSortBy = ['Name of Data Source'];
  heatmapSortDesc = [false];
  generating = false;
  getHeaders = getHeaders
  getHeatmapColumnAttrs = getHeatmapColumnAttrs

  get canClickHeatmapCell(): boolean {
    return Identity.hasRole(['admin', 'dev']);
  }

  get canExport(): boolean {
    return this.rows.length > 0;
  }

  onClickHeatmapCell(column: Column, columnIdx: number) {
    if (this.tracker) {
      const trackerClone: TrackerModel = this.tracker.clone();
      trackerClone.data.heatmap.data.columns = [column];
      this.$emit('click:inspect', {
        disabled: true,
        index: columnIdx,
        tracker: trackerClone,
      });
    }
  }

  onItemClick(row: {[key: string]: string}) {
    this.$emit('click:item', {
      rows: [row],
    });
  }

  onGenerating(value: any) {
    this.generating = value;
  }
}
</script>
